<template>
  <div class="p-4 p-md-5">
    <h3 class="text-center mb-2">NLU Web Portal</h3>
    <h6 class="text-center text-muted">Portal Sign In</h6>
    <div class="row justify-content-center py-md-2">
      <div class="col-lg-9">
        <b-form @submit.prevent="logIn">
          <b-form-group
            label="Email address"
            invalid-feedback="Valid Email Address is required"
          >
            <b-form-input
              type="email"
              placeholder="johndoe@example.com"
              autocomplete="false"
              v-model="$v.form.email.$model"
              :state="validateState($v.form.email)"
              class="i-ctrl"
            />
          </b-form-group>
          <b-form-group
            label="Enter your password"
            invalid-feedback="Password must be atleast 8 characters"
          >
            <b-form-input
              type="password"
              placeholder="Type your password here"
              v-model="$v.form.password.$model"
              :state="validateState($v.form.password)"
              class="i-ctrl"
            />
          </b-form-group>
          <div class="text-right">
              <b-link href="/auth/forgot_password" class="link-base">Forgot Password?</b-link>
          </div>
          <b-alert v-model="error.exists" dismissible variant="danger">
            {{error.body}}
          </b-alert>
          <b-button type="submit" class="w-100 mt-2" variant="danger">
            <b-spinner small v-show="submitting"></b-spinner>
            Log In
          </b-button>
          <div class="text-center mt-3">
            <b-link class="link-base" to="/auth/register">Dont have an account? Create one</b-link>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import * as api from "./api";
export default {
  mixins: [validationMixin],
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      submitting:false,
      error:{
        exists:false,
        body:""
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    logIn() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return false;
      this.submitting=true;

      api.logIn(this.form.email,this.form.password).then((res)=>{

        this.submitting=false;
        const data = res.data;
        const {token,first_name,last_name,account_type,phone_no,permissions} = data;
        const email = this.form.email;
        
        this.$store.dispatch("auth/logIn",{email,token,first_name,last_name,phone_no,account_type,permissions}).then((rules)=>{
          this.$ability.update(rules);
          if(this.$can("approve_application")){
            this.$router.push({path:"/",query:{status:"verified"}});
          }else if(this.$can("view_reports")){
            this.$router.push({path:"/reports"});
          }
          else{
            this.$router.push({path:"/"});
          }
        });
      }).catch((err)=>{
        if(err.response.status==401){
          this.submitting=false;
          this.error.exists = true;
          this.error.body = "Invalid email/password";
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.link-base{
    color:#707070;
}
.link-base:hover{
    color: #404040;
    outline-color: #404040;
}

</style>